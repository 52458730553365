<template>
  <div class="view pa24">
    <el-dialog
        :title="title"
        width="800px"
        :modal-append-to-body="false"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :before-close="closeDialog"
    >
     <div class="dialogBody">
      <commonTable
          :tableData="tableData"
          :total="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
        <template v-slot:table>
          <el-table-column
              prop="lotNumber"
              align="center"
              label="批次号"
            />

            <el-table-column
              prop="warehouseName"
              align="center"
              label="仓库"
            />
            <el-table-column
              prop="dealerName"
              align="center"
              label="数量"
            >
              <template slot-scope="scope">
                {{ scope.row.boxNum + '箱 + '+scope.row.packNum +'盒'}}
              </template>
            </el-table-column>
            <el-table-column
              prop="nickName"
              align="center"
              label="操作人"
            />
          <el-table-column
              prop="createTime"
              align="center"
              label="操作时间"
          />
        </template>
      </commonTable>
     </div>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { fetchCrkHandleRecord } from '@/api/warehouseManage.js'
export default {
  name: "logOrderDialog",
  data() {
    return {
      dialogVisible: false,
      title: '操作记录',
      tableData: [],
      total: 0,
      pageSize: 10,
      pageNum: 1,
      id:'',
      classId:'',
    };
  },
  components: {
    commonTable
  },
  onShow() {

  },
  methods: {
    openDialog(data, id) {
      this.id = id
      this.classId = data.classId
      this.getList()
    },
    closeDialog() {
      this.dialogVisible = false
    },
    getList() {
      let params = {
        id: this.id,
        classId: this.classId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        // status: 1, // 1入库 2出库
      }
      fetchCrkHandleRecord(params).then(res => {
        if(res.code == 200) {
          this.tableData = res.data.list
          this.total = res.data.total
          this.dialogVisible = true
        }
      })
    },
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.getList();
    },
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.ml10 {
  margin-left: 10 rpx;
}
.dialogBody{
  //max-height: 60vh;
  //overflow-y: scroll;
}
</style>
