<template>
  <div class="view pa24">
    <el-dialog
        :title="title"
        width="800px"
        :modal-append-to-body="false"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :before-close="closeDialog"
    >
      <div class="dialogBody">
        <el-form
            :model="ruleForm2"
            :rules="rules2"
            ref="orderRuleForm2"
            label-width="100px"
            class="ruleForm"
        >
          <el-form-item label="产品类别" prop="classId" name="classId">
            <el-cascader
                v-model="ruleForm2.classId"
                ref="cascader"
                :options="classOptions"
                :props="{
                  checkStrictly: true,
                  label:'name',
                  children: 'children',
                  value: 'id',
                  emitPath:false
              }"
                @change="classIdChange"
            >
            </el-cascader>
            <el-checkbox v-model="ruleForm2.isStrategy" :label="true" border size="small" @change="chooseStrategy"
                         style="margin-left: 20px" v-if="hasSetting">推荐库位
            </el-checkbox>
          </el-form-item>
          <p v-if="ruleForm2.isStrategy" style="margin-left: 13%;position: relative;top: -12px;color: red">
            当前上架策略：{{ strategyInfo.name }}({{ strategyInfo.remarks }})</p>
          <el-form-item label="批次" class="strategy" v-if="ruleForm2.isStrategy&&strategyInfo.code=='BATCH'">
            <el-input v-model="ruleForm2.lotNumber" label="批次" @change="inputLotNumber"></el-input>
          </el-form-item>
          <el-form-item label="到期日期" class="strategy" v-if="ruleForm2.isStrategy&&strategyInfo.code=='DATE'">
            <el-date-picker
                @change="chooseDate"
                v-model="ruleForm2.expireTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="推荐库位" class="strategy" v-if="ruleForm2.isStrategy">
            <el-input v-model="ruleForm2.warehouseName" disabled></el-input>
          </el-form-item>
          <el-form-item label="箱数" prop="boxNum">
            <el-input-number v-model="ruleForm2.boxNum" :min="0" label="出库箱数"></el-input-number>
          </el-form-item>
          <el-form-item label="盒数" prop="packNum">
            <el-input-number v-model="ruleForm2.packNum" :min="0" label="出库箱数"></el-input-number>
          </el-form-item>

        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button class="btncss" @click="confirmAddRow">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {fetchAddOrUpdateDeal} from '@/api/companyManage.js'
import {getStrategyInfo, getStrategyWarehouse} from '@/api/crkStrategy'

export default {
  name: "ckOrderDialog",
  components: {},
  props: ['classOptions'],

  data() {
    return {
      dialogVisible: false,
      title: '添加订单',
      ruleForm2: {
        className: '',
        classId: 0,
        boxNum: 0,
        packNum: 0,
        lotNumber: '',
        warehouseId: '',
        warehouseName: '',
        isStrategy: false,
        expireTime: '',
        strategy: '',
        regionId:'',
        localId:'',
        busName:'',
        goodsCode:'',
        specCode:'',
        specName:'',
      },
      rules2: {
        classId: [
          {required: true, message: '请选择产品类别', trigger: 'blur'},
        ],
      },
      type: 'add',
      editIndex: null,
      strategyInfo: '',
      hasSetting:false
    };
  },
  onShow() {

  },
  mounted() {

  },
  methods: {
    inputLotNumber() {
      this.chooseStrategy()
    },
    chooseDate() {
      this.chooseStrategy()
    },
    getSetting() {
      getStrategyInfo({type: 1}).then(res => {
        if (res.data) {
          this.strategyInfo = res.data
        }
      })
    },
    chooseStrategy(val) {
      if (!this.ruleForm2.isStrategy){
        return;
      }
      if ("CP" === this.strategyInfo.code && !this.ruleForm2.classId) {
        this.$message.warning('请选择类别')
        return;
      }
      if ('BATCH' === this.strategyInfo.code && !this.ruleForm2.lotNumber) {
        this.$message.warning('请填写对应批次')
        return;
      }
      if ('DATE' === this.strategyInfo.code && !this.ruleForm2.expireTime) {
        this.$message.warning('请选择到期日期')
        return;
      }
      let params = {
        strategy: this.strategyInfo.code,
        classId: this.ruleForm2.classId,
        lotNumber: this.ruleForm2.lotNumber,
        expireTime: this.ruleForm2.expireTime
      }
      getStrategyWarehouse(params).then(res => {
        this.ruleForm2.warehouseId = ''
        this.ruleForm2.warehouseName = ''
        if (res.data) {
          console.log(res)
          this.ruleForm2.warehouseId = res.data.warehouseId
          this.ruleForm2.regionId = res.data.regionId
          this.ruleForm2.localId = res.data.localId
          this.ruleForm2.warehouseName = res.data.warehouseName
        }
      })
    },
    openDialog(type, data, index,hasSetting) {
      console.log("hasSetting",hasSetting)
      this.hasSetting = hasSetting
      if (this.hasSetting){
        this.getSetting()
      }
      this.type = type
      this.editIndex = index ? index : null
      let v = this
      if (type === 'add') {
        this.title = '添加产品'
        this.ruleForm2 = {
          className: '',
          classId: 0,
          boxNum: 0,
          packNum: 0,
          lotNumber: '',
          warehouseId: '',
          warehouseName: '',
          isStrategy: false,
          expireTime: '',
          strategy:'',
          regionId:'',
          localId:''
        }
      } else {
        this.title = '编辑产品'
        // 回显数据
        this.getData(data)
      }
      this.dialogVisible = true
    },
    closeDialog() {
      this.dialogVisible = false
    },
    resetForm() {
      this.$refs.orderRuleForm2.resetFields();
    },
    // 回显
    getData(data) {
      this.ruleForm2 = {
        className: data.className,
        classId: data.classId,
        boxNum: data.boxNum,
        packNum: data.packNum,
        lotNumber: data.lotNumber,
        warehouseId: data.warehouseId,
        warehouseName: data.warehouseName,
        isStrategy: data.isStrategy,
        expireTime: data.expireTime,
        strategy: data.strategy,
        regionId:data.regionId,
        localId:data.localId
      }
    },
    classIdChange(e) {
      let node = this.$refs["cascader"].getCheckedNodes()[0]
      this.getClassName(node)
    },
    getClassName(data, str = '') {
      str += '/' + data.label
      console.log(str, '111')
      if (data.parent) {
        console.log(str, '222')
        this.getClassName(data.parent, str)
      } else {
        let res = str.split('/').reverse().join('/')
        this.ruleForm2.className = res.slice(0, -1)
        console.log('this.ruleForm.className', this.ruleForm2.className)
      }
      this.ruleForm2.busName = data.data.busName
      this.ruleForm2.goodsCode = data.data.goodsCode
      this.ruleForm2.specCode = data.data.specCode
      this.ruleForm2.specName = data.data.specName
    },
    confirmAddRow() {
      console.log('this.$refs.orderRuleForm2', this.$refs.orderRuleForm2)
      this.$refs.orderRuleForm2.validate((valid) => {
        if (!this.ruleForm2.classId) {
          this.$message.error('请选择类别')
          return
        }
        if (this.ruleForm2.boxNum ==0 && this.ruleForm2.packNum == 0){
          this.$message.error('请填写箱数/盒数');
          return
        }
        if (valid) {
          if (this.ruleForm2.isStrategy){
            this.ruleForm2.strategy = this.strategyInfo.code
            this.ruleForm2.strategyName =  this.strategyInfo.name+"("+this.strategyInfo.remarks+")"
          }
          if (this.type === 'add') {
            this.$emit('add', this.ruleForm2)
          } else {
            this.$emit('edit', this.ruleForm2, this.editIndex)
          }
          this.closeDialog()
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.ml10 {
  margin-left: 10 rpx;
}

.dialogBody {
  //max-height: 50vh;
  //overflow-y: scroll;
}

.strategy {
  width: 43%;
}
</style>
