<template>
  <div class="view pa24">
    <el-page-header @back="goBack" content="入库单" style="margin-bottom: 20px">
    </el-page-header>
    <el-form
          :model="ruleForm"
          :rules="rules"
          ref="orderRuleForm"
          label-width="120px"
          class="ruleForm"
      >
        <el-form-item label="入库单号" prop="lotNumber">
          <el-input v-model="ruleForm.lotNumber"   placeholder="请输入单号" style="width: 30%;" :disabled="!showFlag"></el-input>
        </el-form-item>
        <el-form-item label="生产批号">
          <el-input v-model="ruleForm.produceNumber"   placeholder="请输入生产批号" style="width: 30%;" :disabled="!showFlag"></el-input>
        </el-form-item>
        <el-form-item  label="默认入库仓库" prop="warehouseId" >
          <!-- <el-select v-model="ruleForm.warehouseId" placeholder="请选择" position="'bottom'" :disabled="!showFlag">
                  <el-option
                    v-for="item in warehouseOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
          </el-select> -->
          <el-cascader
          :disabled="!showFlag"
            v-model="ruleForm.warehouseId"
            placeholder="请选择"
            :options="warehouseOptions"
            :emitPath="false"
            :props="{value:'id',label:'name',children:'children'}"
            @change="handleChange"></el-cascader>
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input v-model="ruleForm.remarks" placeholder="请输入备注" style="width: 30%;" :disabled="!showFlag"></el-input>
        </el-form-item>
        <el-button type="primary" icon="el-icon-plus" style="margin-bottom: 20px" @click="handleAddRow" v-if="showFlag">添加产品</el-button>
    </el-form>

    <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        prop="className"
        label="产品类别"
        align="center"
       >
      </el-table-column>
      
      <el-table-column
        prop="busName"
        label="商家名称"
        align="center"
       >
       <template slot-scope="scope">
        {{ scope.row.busName || '--'}}
       </template>
      </el-table-column>
      <el-table-column
        prop="goodsCode"
        label="货品编码"
        align="center"
       >
       <template slot-scope="scope">
        {{ scope.row.goodsCode || '--'}}
       </template>
      </el-table-column>

      <el-table-column
        prop="specCode"
        label="规格码"
        align="center"
       >
       <template slot-scope="scope">
        {{ scope.row.specCode || '--'}}
       </template>
      </el-table-column>
      <el-table-column
        prop="specName"
        label="规格名称"
        align="center"
       >
       <template slot-scope="scope">
        {{ scope.row.specName || '--'}}
       </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="boxNum"
        label="箱数（箱）"
        >
      </el-table-column>
      <el-table-column
       align="center"
        prop="packNum"
        label="盒数（盒）"
        >
      </el-table-column>
     <el-table-column
        align="center"
        prop="finishBoxNum"
        label="已完成数"
        v-if="!showFlag"
        >
        <template slot-scope="scope">
          <!-- {{ scope.row.finishBoxNum }}（箱） + {{scope.row.finishPackNum }}（盒） -->
          <span :class="(dataStatus!== 0 && scope.row.finishBoxNum!==scope.row.boxNum) ? 'redColor' : ''">{{scope.row.finishBoxNum||0}}</span>箱 +
          <span :class="(dataStatus!== 0 && scope.row.finishPackNum!==scope.row.packNum) ? 'redColor' : ''">{{scope.row.finishPackNum||0}}</span>盒
        </template>
      </el-table-column>

      <!-- <el-table-column
          v-if="hasSetting"
          align="center"
          prop="strategyName"
          label="入库策略"
      >
      </el-table-column>
        <el-table-column
            v-if="hasSetting"
            align="center"
            prop="expireTime"
            label="到期日期"
        >
        </el-table-column>
        <el-table-column
            v-if="hasSetting"
            align="center"
            prop="lotNumber"
            label="生产批次"
        >
        </el-table-column>

      <el-table-column
          v-if="hasSetting"
          align="center"
          prop="warehouseName"
          label="推荐库位"
      >
      </el-table-column> -->
      <el-table-column align="center" width="200" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEditRow(scope.row, scope.$index)"  v-if="showFlag">
              编辑
            </el-button>
            <el-button type="text" @click="deleteRow(scope.$index)" class="redColor"  v-if="showFlag">删除</el-button>
            <el-button type="text" @click="lookLog(scope.row)" v-if="!showFlag">查看记录</el-button>
          </template>
        </el-table-column>
    </el-table>

    <div class="botBtns" v-if="showFlag">
        <el-button  @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm">确认</el-button>
    </div>
    <!--添加弹窗-->
     <AddOrderDialog ref="addOrderDialog" :classOptions="classOptions" @add="addRow" @edit="editRow"></AddOrderDialog>
    <!---记录弹窗---->
    <LogOrderDialog ref="logOrderDialog"></LogOrderDialog>
   </div>
</template>
<script>
import { pageClassList } from '@/api/customPageClass.js'
import { selectAllWarehouse,fetchAddCrkProduce,fetchCrkProduceDetail,selectProduceLotNumber, fetchWarehouseTree } from '@/api/warehouseManage.js'
import AddOrderDialog from "./components/AddOrderDialog";
import LogOrderDialog from './components/LogOrderDialog'
import strategy from "@/pages/strategy/index.vue";
import {getAllStrategy,getMySetting} from "@/api/crkStrategy"

export default {
  computed: {
    strategy() {
      return strategy
    }
  },
  data() {
    return {
      warehouseOptions: [],
      classOptions:[],
      ruleForm: {
          dealerId: '',
          remarks: '',
          warehouseId:'',
          produceNumber:'',
          lotNumber: '',
      },
      rules: {
        warehouseId: [
          {required: true, message: '请选择仓库', trigger: 'blur'},
        ],
        lotNumber: [
          {required: true, message: '请输入单号', trigger: 'blur'},
        ],
      },
      labelName: '',
      tableData: [],
      lotNumber: '',
      showFlag:true,
      strategyList:[],
      dataStatus: '',
      hasSetting:false
    }
  },
  components: {
    AddOrderDialog,
    LogOrderDialog
  },
  created() {
    // 获取所有分类
    this.getClassList()
    // 获取所有仓库
    this.getWarehouse()
    this.getInStrategy()
    // console.log('---------类型----------',this.$route.query)
    if(this.$route.query.data) { // 编辑回显
      let data = JSON.parse(this.$route.query.data)
      this.id = data.id
      this.lotNumber = data.lotNumber
      this.ruleForm.lotNumber = data.lotNumber
      let arr = []
      if(data.warehouseId) {
        arr.push(data.warehouseId)
      }
      if(data.regionId) {
        arr.push(data.regionId)
      }
      if(data.localId) {
        arr.push(data.localId)
      }
      this.ruleForm.warehouseId = arr
      this.ruleForm.produceNumber = data.produceNumber
      this.getProduceDetail()
      this.dataStatus = data.status
    }else {
      this.getLotNumber()
      this.dataStatus = 0
    }
    let type = this.$route.query.type;
    this.hasSetting = this.$route.query.hasSetting
    if ('detail'==type || ('edit'==type && data.status ==1)){
      this.showFlag = false
    }
  },
  methods: {
    getInStrategy(){
      getAllStrategy({type:1}).then(res=>{
        if (res.data){
          this.strategyList = res.data
        }
      })
    },
    getLotNumber(){
      selectProduceLotNumber().then(res=>{
        this.ruleForm.lotNumber = res.data
      })
    },
    goBack() {
      this.cancel()
    },
    getProduceDetail() {
      let params = {
        produceId: this.id,
        pageNum: 1,
        pageSize: 9999,
      }
      fetchCrkProduceDetail(params).then(res => {
        if(res.code === 200) {
          this.tableData = res.data.list
          this.tableData.forEach(item=>{
            console.log(item.isStrategy)
            if (item.isStrategy){
              let obj = this.strategyList.filter(s=>s.code===item.strategy)[0];
              if (obj){
                item.strategyName = obj.name+"("+obj.remarks+")"
              }
            }
          })
        }
      })
    },
    handleAddRow() {
      this.$refs.addOrderDialog.openDialog('add',null,null,this.hasSetting)
    },
    addRow(data) {
      this.tableData.push(data)
    },
    editRow(data,index) {
      this.tableData.splice(index,1, data)
    },
    handleEditRow(row,index) {
      this.$refs.addOrderDialog.openDialog('edit',row,index)
    },
    deleteRow(index) {
      this.tableData.splice(index, 1)
    },
    lookLog(row) {
      this.$refs.logOrderDialog.openDialog(row, this.id)
    },
    getClassList() {
        let params={
          pageNum: 1,
          pageSize: 999,
          type:0
        }
        pageClassList(params).then(res => {
          this.classOptions = this.formatData(res.data.list)
        })
      },
      // 过滤掉空的children
      formatData(data) {
        for (var i = 0; i < data.length; i++) {
          if (data[i].children == null || data[i].children.length == 0) {
            data[i].children = undefined;
          } else if (data[i].children.length > 1) {
            this.formatData(data[i].children);
          }
        }
        return data;
      },
      getWarehouse() {
        fetchWarehouseTree().then(res => {
          this.warehouseOptions = res.data
          // if (!this.ruleForm.warehouseId && this.warehouseOptions){
          //   this.ruleForm.warehouseId = this.warehouseOptions[0].id
          // }
        })
      },
      cancel() {
        this.$router.push({
            path: '/crk/crkProduce'
          })
      },
      confirm() {
       this.$refs.orderRuleForm.validate((valid) => {
         if(valid) {
            if(this.tableData.length == 0) {
              this.$message.error('请选择产品类别')
              return
            }else {
               let params = {
                  warehouseId: this.ruleForm.warehouseId.length ? this.ruleForm.warehouseId[this.ruleForm.warehouseId.length -1] : '',
                  lotNumber: this.ruleForm.lotNumber,
                  remarks: this.ruleForm.remarks,
                 produceNumber:this.ruleForm.produceNumber
                }
                let reTable = JSON.parse(JSON.stringify(this.tableData))
                reTable.forEach(item => {
                  delete item.className
                })
                params.detailDtos = reTable

                if(this.id) { // 有id 编辑
                  params.id = this.id
                }
              fetchAddCrkProduce(params).then(res => {
                  if(res.code == 200) {
                    this.cancel()
                  }
                })
            }

         }
       })


      }

  },
}
</script>
<style scoped>
.redColor{
  color: #f00;
}
.botBtns{
  display: flex;
  padding-top: 20px;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fff;
  border-color: #E4E7ED;
  color: #000;
  cursor: not-allowed;
}
</style>
